import { useCallback, useEffect, useMemo, useState } from "react";
import { v1 } from "src/Utils/ApiVersions";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { useGetRequest } from "../ApiWrappers/useGetRequest";
import { useClinicContext } from "../ClinicContext";

export enum ClinicStorageError {
    NONE = "NONE",
    NO_ACTIVE_STORAGE_LICENSE = "NO_ACTIVE_STORAGE_LICENSE",
    STORAGE_LICENSE_NOT_ACTIVATED = "STORAGE_LICENSE_NOT_ACTIVATED",
}
export interface IStorageLicenceInfo {
    clinicId : string,
    totalMaxStorageInGb: number,
    totalAvailableStorageInGb: number,
    licenseEndDate: string,
    storageError:ClinicStorageError,
    entitlementId:string;
}
export const useGetStorageLicenceInfo = () => {
    const baseUrl = useMemo(() => Config.GetConfigData().auraStorageLicensingService.serviceUrl,[]);
    const scope = Config.GetConfigData().auraStorageLicensingService?.scopes[0];
    const [storageInfo, setStorageInfo] = useState<IStorageLicenceInfo>();
    const [getDetails, data, loading, error] = useGetRequest<IStorageLicenceInfo>()
    const {clinicInfo } = useClinicContext();
    
   const getStorageInfo = useCallback(() => {
    if(clinicInfo) {
        const requestUrl = `${baseUrl}api/${v1}/clinic/${clinicInfo.clinicId}/license`;
        getDetails(scope, requestUrl)
     }
   }, [getDetails, scope, baseUrl, clinicInfo])

   useEffect(() => {
    if(clinicInfo) {
        getStorageInfo();
    }
   },[getStorageInfo, clinicInfo])

   useEffect(() => {
    if(data ) {
        setStorageInfo(data)
    }
   }, [data])
      return { 
        getStorageInfo,
        storageInfo,
        loading,
        error
     };
  }