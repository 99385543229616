import { useTranslation } from "react-i18next";
import './RenewLicenseButton.css'
import { useClinicContext } from "src/Utils/Hooks/ClinicContext";
type IRenewLicenseButton = {
    onClick: () => void;
}
const RenewLicenseButton: React.FC<IRenewLicenseButton> = ({ onClick }) => {
    const { t } = useTranslation();
    const { currentUser } = useClinicContext()
    var icon = 'bi bi-arrow-clockwise';
    return (
        <>
            <button
                type='button'
                className='btn renew-buttonWrapper'
                data-testid={t('Renew')}
                id='renew'
                style={{
                    background: 'none',
                    color: '#A8927D',
                    borderColor: '#A8927D',
                    fontSize: '14px',
                    border: 'none'
                }}
                onClick={() => { onClick() }}
                disabled={!currentUser?.isClinicAdmin}
            >
                <span className='renew-iconWrapper'>
                    <i className={`${icon}`}></i>
                </span>
                <span className='renew-textWrapper'>
                    {t('Renew')}
                </span>

            </button>
        </>
    )
}
export default RenewLicenseButton;