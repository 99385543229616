import ButtonWithIcon from 'src/Components/ButtonWithIcon/ButtonWithIcon'
import './NoLicenseActivated.css'
import { useCallback, useState } from 'react';
import AddNewLicence from '../AddNewLicence/AddNewLicence';
import { InfoMessageType } from 'src/Components/InfoBar/InfoBar';
import { useTranslation } from 'react-i18next';
import { useClinicContext } from 'src/Utils/Hooks/ClinicContext';
import ActivateLicenseSuccessInstruction from '../ActivateLicenceSuccessInstruction/ActivateLicenseSuccessInstruction';
interface INoLicenseActivatedParams {
    clinicId: string;
    setInfo: (message: string, type: InfoMessageType) => void;
    updatePageLoader: (load: boolean) => void;
    updateList: () => void;
}
const NoLicenseActivated: React.FC<INoLicenseActivatedParams> = ({ clinicId, setInfo, updatePageLoader, updateList }) => {
    const [showAddNewLicence, setShowAddNewLicence] = useState<boolean>(false);
    const [showActivationSuccessInstruction, setShowActivationSuccessInstruction] = useState<boolean>(false);

    const SetInfoMessage = useCallback((message: string, messageType: InfoMessageType) => {
        if (messageType === InfoMessageType.success) {
            setShowActivationSuccessInstruction(true);
        }
        else {
            setInfo(message, messageType);
        }
    }, [setInfo])

    const { currentUser } = useClinicContext()
    const { t } = useTranslation();

    const onCloseDialog = useCallback(() => {
        setShowActivationSuccessInstruction(false);
        updateList();
    }, [setShowActivationSuccessInstruction, updateList])

    return (
        <>
            {
                showActivationSuccessInstruction &&
                <ActivateLicenseSuccessInstruction
                    onClose={onCloseDialog}
                    setInfo={setInfo}
                    updatePageLoader={updatePageLoader}
                />
            }
            {
                showAddNewLicence &&
                <AddNewLicence
                    clinicId={clinicId}
                    setInfo={SetInfoMessage}
                    updatePageLoader={updatePageLoader}
                    isUserLicence={true}
                    onClose={() => { setShowAddNewLicence(false); }} />
            }
            <div className='noLicenseContainer'>
                <div className='title'>{t('NoLicenceActivated')}</div>
                <div className='text mt-3'>{t('ActivationReminderMessage')}</div>
                <div className='mt-4 d-flex justify-content-center'>
                    <ButtonWithIcon
                        backgroundColor='linear-gradient(109.8deg, rgba(168, 146, 125, 0.6) 0%, #A8927D 100%)'
                        onClick={() => { setShowAddNewLicence(true) }}
                        label={t('AddLicence')}
                        icon='bi bi-plus-lg'
                        testId='newUser'
                        color='white'
                        disable={!currentUser?.isClinicAdmin}
                    />
                </div>
                <div className='text mt-3'>{t('DownloadAppLocationInstruction')}</div>
            </div>
        </>
    )
}
export default NoLicenseActivated