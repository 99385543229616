import { IStorageLicenceInfo } from "src/Utils/Hooks/ManageLicenses/useGetStorageLicenceInfo";
import RenewInfo from "./RenewInfo";
import GridViewWithHeaderNode from "src/SharedComponents/GridViewWithHeader/GridViewWithHeaderNode";
import { useEffect, useMemo, useState } from "react";
import './StorageInfo.css'
import { InfoMessageType } from "src/Components/InfoBar/InfoBar";
type IClinicStorageInfo = {
    storageInfo: IStorageLicenceInfo;
    setInfo: (message: string, type: InfoMessageType) => void;
    updatePageLoader: (load: boolean) => void;
}
const StorageInfo: React.FC<IClinicStorageInfo> = ({ storageInfo ,updatePageLoader, setInfo }) => {

    const [percentageUsed, setpercentageUsed] = useState<number>();
    const usedClinicStorage = useMemo(() => {
        if (storageInfo?.totalMaxStorageInGb > 0 && storageInfo?.totalAvailableStorageInGb >= 0) {
            var usedStorage = storageInfo.totalMaxStorageInGb - storageInfo.totalAvailableStorageInGb;
            return Math.round((usedStorage) * 10) / 10; // 1 decimal number
        }
        return 0;
    }, [storageInfo]);

    useEffect(() => {
        if (storageInfo) {
            let percenage = (usedClinicStorage / storageInfo.totalMaxStorageInGb) * 100;
            let roudenNUmber = Math.round(percenage * 10) / 10; // 1 decimal number
            setpercentageUsed(roudenNUmber)
        }
    }, [storageInfo, usedClinicStorage])

    const percentageusedText = `${percentageUsed}%`;
    const isStorageAboutToExceed = () => percentageUsed && percentageUsed > 90 ? true : false;
    const progressbarColor = isStorageAboutToExceed() ? 'red' : '#A8927D'
    return (
        <>
            {storageInfo &&
                <>
                    <div className="row mt-3">
                        <div className="col ">
                            <span className="storagePercentage">{percentageusedText}</span>
                            <span className='px-2 text'>Storage in use</span>
                        </div>
                    </div>
                    <GridViewWithHeaderNode title="Cloud Storage"
                        headerNode={<RenewInfo
                            licenseEndDate={storageInfo.licenseEndDate}
                            storageError={storageInfo.storageError}
                            setInfo={setInfo}
                            updatePageLoader={updatePageLoader}
                            entitlementId={storageInfo.entitlementId}
                        />}>
                        <div className="mt-5" style={{ width: '98%' }}>
                            <div className="progress " style={{ height: '4px' }}>
                                <div className="progress-bar" role="progressbar"
                                    style={{
                                        width: percentageusedText,
                                        backgroundColor: progressbarColor
                                    }}>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3 mb-2">
                            <div style={{ width: `${isStorageAboutToExceed() ? '30%' : '85%'}` }}>
                                <span className="storageInfo" style={{ color: progressbarColor }}>{percentageusedText} ({usedClinicStorage} GB) in use</span>
                            </div>
                            {isStorageAboutToExceed() &&
                                <div style={{ width: '55%' }}>
                                    <span className="storageInfo" style={{ color: 'red' }}>Data synchronization will be paused when the memory is full.</span>
                                </div>
                            }
                            <div style={{ width: '15%' }}>
                                <span className="float-end storageInfo">{storageInfo.totalMaxStorageInGb} GB</span>
                            </div>
                        </div>

                    </GridViewWithHeaderNode>
                </>
            }
        </>
    )
}
export default StorageInfo;