import { useTranslation } from "react-i18next";
import { formatDateString } from "src/Utils/DateFormatting/DateFormattter";
import { useClinicContext } from "src/Utils/Hooks/ClinicContext";
import { ILicenceRequest, LicenceType } from "src/Utils/Hooks/ManageLicenses/useActivateLicence";
import { ClinicStorageError } from "src/Utils/Hooks/ManageLicenses/useGetStorageLicenceInfo";
import { useRefreshLicence } from "src/Utils/Hooks/ManageLicenses/useRefreshLicense";
import './RenewInfo.css'
import RenewLicenseButton from "../RenewLicenseButton/RenewLicenseButton";
import { InfoMessageType } from "src/Components/InfoBar/InfoBar";
import { useEffect } from "react";

type ILicenceValidity = {
    entitlementId:string;
    licenseEndDate: string,
    storageError: ClinicStorageError,
    setInfo: (message: string, type: InfoMessageType) => void;
    updatePageLoader: (load: boolean) => void;
}
const RenewInfo: React.FC<ILicenceValidity> = ({ licenseEndDate, storageError, setInfo,  updatePageLoader, entitlementId}) => {
    const { t } = useTranslation();
    var borderStyle = storageError === ClinicStorageError.NO_ACTIVE_STORAGE_LICENSE ? 'expired' : '';
    const {currentUser} = useClinicContext()
    const { refreshLicensesInfo, refreshLicenseInfoError, refreshLicensesInfoLoading, refreshLicence } = useRefreshLicence();
    const refreshLicenceReq: ILicenceRequest = {
        entitlementId: entitlementId,
        licenseType: LicenceType.storageLicence
    };
    useEffect(() => {
        if(refreshLicenseInfoError !== '') {
            setInfo(refreshLicenseInfoError, InfoMessageType.error)
        }
    }, [refreshLicenseInfoError, setInfo])

    useEffect(() => {
        if(refreshLicensesInfo) {
            setInfo('License details updated successfully. ', InfoMessageType.success)
        }
    }, [refreshLicensesInfo, setInfo])

    useEffect(() => {
            updatePageLoader(refreshLicensesInfoLoading)
    }, [refreshLicensesInfoLoading, updatePageLoader])
    return (
        <>
            <div className='d-flex justify-content-end'>
                <span style={{marginRight:'10px'}}>
                    {
                        <button type="button" className={`btn storage-renew-validity ${borderStyle}`}>
                            {`${t('ValidUnil')} ${formatDateString(licenseEndDate)}`}
                        </button>
                    }
                </span>
                {currentUser && 
                    <span> 
                       <RenewLicenseButton 
                       onClick={() => {refreshLicence(refreshLicenceReq, currentUser.clinicUser.clinicId)}}/>
                    </span>
                }
            </div>
        </>
    )
}
export default RenewInfo;