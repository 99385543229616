import tableStyles from 'src/Utils/CssModules/table.module.css';
import licenseKeyInUse from 'src/images/LicenseKeyInuse.svg';
import licenseKeyInNoUse from 'src/images/LicenseKeyInNoUse.svg'
import { IClinicLicenseUserInfo, ILicensesInfo, LicenseType } from "src/Utils/Hooks/ManageLicenses/useGetLicensesInfo";
import './LicenseInfo.css'
import { formatDateString } from "src/Utils/DateFormatting/DateFormattter";
import { useTranslation } from "react-i18next";
import MoreOptionsMenu from 'src/Components/MoreOptions/MoreOptions';
import ReleaseLicence from '../ReleaseLicence/ReleaseLicence';
import { InfoMessageType } from 'src/Components/InfoBar/InfoBar';
import ButtonWithIcon from 'src/Components/ButtonWithIcon/ButtonWithIcon';
import { useRefreshLicence } from 'src/Utils/Hooks/ManageLicenses/useRefreshLicense';
import { ILicenceRequest, LicenceType } from 'src/Utils/Hooks/ManageLicenses/useActivateLicence';
import { useEffect } from 'react';
import { logger } from 'src/Utils/LoggerService/LoggerService';
import React from 'react';
import { useClinicContext } from 'src/Utils/Hooks/ClinicContext';
import divStyles from 'src/Utils/CssModules/div.module.css';

interface ILicenseInfo {
    entitlementId: string;
    licenseType: string;
    noOfUnusedLicenses: number;
    licensesList: IClinicLicenseUserInfo[];
    licenseEndDate: string;
    clinicId: string;
    setInfo: (message: string, type: InfoMessageType) => void;
    updateLicenceInfo: (licenceInfo: ILicensesInfo) => void;
    updatePageLoader: (load: boolean) => void;
}
const LicenseInfo: React.FC<ILicenseInfo> = ({ entitlementId, licenseType, licensesList, noOfUnusedLicenses, licenseEndDate, clinicId, updateLicenceInfo, setInfo, updatePageLoader }) => {

    const { refreshLicensesInfo, refreshLicenseInfoError, refreshLicensesInfoLoading, refreshLicence } = useRefreshLicence();
    const {currentUser} = useClinicContext()
    const refreshLicenceReq: ILicenceRequest = {
        entitlementId: entitlementId,
        licenseType: LicenceType.userLicence
    };
    useEffect(() => {
        if (refreshLicensesInfo) {
            logger.logInformation(`License is refreshed successfully. Entitlement Id is ${entitlementId}, clinicId is ${clinicId}.`)
            setInfo('License details updated successfully', InfoMessageType.success);
            updateLicenceInfo(refreshLicensesInfo)
        }
        else if (refreshLicenseInfoError) {
            logger.logError(`Error occur while refreshing license. EntitlementId is ${entitlementId}, clinicId is ${clinicId}.`);
            setInfo(refreshLicenseInfoError, InfoMessageType.error);
        }
    }, [refreshLicensesInfo, setInfo, clinicId, entitlementId, updateLicenceInfo, refreshLicenseInfoError])
    useEffect(() => {
        updatePageLoader(refreshLicensesInfoLoading);
    }, [refreshLicensesInfoLoading, updatePageLoader])

    const { t } = useTranslation();
    return (
        <>
            <div className='row mt-4'>
                <div className={`${tableStyles.fixTableHead} ${tableStyles.roundedTable} padding px-4`}>
                    <table style={{ width: '100%' }} data-testid="table" className={`table table-striped`}>
                        <thead>
                            <tr>
                                <th className={`${tableStyles.heading} col-5 licenseType`}>
                                    {licenseType}
                                    <span className='mx-2 entitlementId'>
                                        {entitlementId}
                                    </span>
                                </th>
                                <th className={tableStyles.heading}> </th>
                                <th>

                                    <div className='d-flex justify-content-end'>
                                        <span>
                                            {
                                                licenseType !== LicenseType.AuraAppPermanent &&
                                                <button type="button" style={{width: '100%'}} className={`btn validity ${new Date(licenseEndDate) < new Date() ? 'expired' : ''}`}>
                                                    {`${t('ValidUnil')} ${formatDateString(licenseEndDate)}`}
                                                </button>
                                            }
                                        </span>

                                        <span>
                                            <ButtonWithIcon
                                                label={t('Renew')}
                                                testId="renew"
                                                icon="bi bi-arrow-clockwise fs-6"
                                                onClick={() => { refreshLicence(refreshLicenceReq, clinicId) }}
                                                backgroundColor='none'
                                                border='none'
                                                disable= {!currentUser?.isClinicAdmin}
                                                color='#A8927D' />
                                        </span>
                                        <MoreOptionsMenu disabled={true} children={undefined} />
                                    </div>

                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                licensesList.map((item, index) =>
                                    <tr key={index}>
                                        <td className={tableStyles.data}>
                                            <span>
                                                <img src={licenseKeyInUse} className="key" alt="licenseKeyInUse" />
                                                {item.userEmail}
                                            </span>
                                        </td>
                                        <td className={tableStyles.data}>{item.appDeviceInfo}</td>
                                        <td>
                                            <div className={`d-flex justify-content-end ${!currentUser?.isClinicAdmin? divStyles.disabled : ''}`}>
                                                <MoreOptionsMenu id={'three-dots-licence-info'}>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li>
                                                            <ReleaseLicence
                                                                clinicId={clinicId}
                                                                sessionId={item.sessionId}
                                                                setInfo={setInfo}
                                                                updatePageLoader={updatePageLoader} />
                                                        </li>
                                                    </ul>
                                                </MoreOptionsMenu>
                                            </div>

                                        </td>
                                    </tr>)
                            }
                            {Array.from({ length: noOfUnusedLicenses }).map((item, index) => (
                                <tr key={index}>
                                    <td className={tableStyles.data}>
                                        <span>
                                            <img src={licenseKeyInNoUse} className="key" alt="licenseKeyNotUsed" />
                                            {`(${t('NotInUse')})`}
                                        </span>
                                    </td>
                                    <td className={tableStyles.data}></td>
                                    <td>
                                        <div className='d-flex justify-content-end'>
                                            <MoreOptionsMenu disabled={true} children={null} />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
export default React.memo(LicenseInfo);