import {useCallback, useState } from 'react';
import { AuthConfig } from 'src/AuthConfig';
import { Config } from '../../ConfigService/LoadConfig';
import axios from 'axios';
import { v1 } from 'src/Utils/ApiVersions';
import { logger } from 'src/Utils/LoggerService/LoggerService';
import { useClinicContext } from '../ClinicContext';

export interface IUsers {
   clinicId:string,
   userId:string,
   roles:string[]
}
export interface IDevice {
   deviceId:string,
   state:string,
   name:string
   serialNumber:string
}
export interface IDistributor {
  distributorId:string,
  name:string,
  email:string,
  contactNumber:string,
  streetAddress:string,
  city?:string,
  country?:string,
  zipCode?:string,
}
export interface IClinicInfo {
    clinicId:string,
    name:string,
    devices:IDevice[],
    locations:string[],
    email:string,
    contactNumber:string,
    address:string,
    distributorDetail:IDistributor,
    isKol:boolean,
    supportContactDetail: ISupportContactDetail,
    cloudStorageActivated: boolean,
  }
  export interface ISupportContactDetail {
    email: string,
    contactNumber: string,
    supportChannel: string,
    supportChannelType: string
  }
export enum ClinicRoles {
  ClinicAdmin = "clinic-admin",
  ClinicUser = "clinic-user",
}
export const useGetClinicInfo = () => {
    const [clinicDetails, setClinicDetails] = useState<IClinicInfo>();
    const [clinicError, setClinicError] = useState<string>('');
    const [clinicLoading, setClinicLoading] = useState<boolean>(false);
    const [statusCode, setStatusCode] = useState()

    const {setClinicInfo } = useClinicContext();
  const getClinicInfo = useCallback(() => {
      setClinicLoading(true);
      setClinicError('');
      setStatusCode(undefined);
      const scope = Config.GetConfigData().auraWebService?.scopes[0];
      const baseUrl = Config.GetConfigData().serviceUrl;
      const requestUrl = `${baseUrl}api/${v1}/clinic/me`;
      AuthConfig.getToken(scope).then(
          (success) => {
            const headers = {
              Authorization: `Bearer ${success}`,
            };
            logger.logInformation(`Access token received successfully for scope :  ${scope} and request url: ${requestUrl}`);
            axios
              .get(requestUrl, { headers })
              .then(
                (response) => {
                  setClinicDetails(response.data);
                  setClinicInfo(response.data)
                  const status = response.status ? `Status Code: ${response.status}` : 'Status Code: N/A';
                  logger.logInformation(`Get request to ${requestUrl} for clinic details was successful and ${status}.`);
                  setClinicError('');
                },
  
                (error) => {
                  var status = 'Status Code: N/A'
                  var errorStatus = error.response && error.response.status;
                  if(errorStatus){
                    status = `Status Code: ${error.response.status}`;
                    setStatusCode(error.response.status)
                  }
                  
                  logger.logError(`Error occured to fetch clinic details for ${requestUrl}: ${status}.`)
                  if(error.message && error.message !== '') {
                    setClinicError(error.message);
                  } else {
                    setClinicError(error.response.data);
                  }
                  setClinicDetails(undefined);
                }
              )
              .finally(() => {
                  setClinicLoading(false);
              });
          },
          (error) => {
            setClinicLoading(false);
            setClinicError(error);
          }
        );
      }
  , [setClinicInfo]);

  return { getClinicInfo, clinicDetails, clinicError, clinicLoading, statusCode };
};
