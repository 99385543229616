import './GridViewWithHeaderNode.css'
export interface IGridViewParams {
    title: string
    children: React.ReactNode,
    headerNode?: React.ReactNode
}
const GridViewWithHeaderNode: React.FC<IGridViewParams> = ({ children, title, headerNode }) => {
    return (
        <>
            <div className='row d-flex mt-4 gridview-node'>
                <div className='m-3'>
                    <div className="mt-3">
                        <span className='gridview-node-header'>
                            <div className='row'>
                                <div className='col-8'>
                                    <b>{title}</b>
                                </div>

                                <div className='col-md-4'>
                                    {headerNode}
                                </div>
                            </div>
                        </span>
                    </div>

                    <hr style={{ color: '#A8927D', width: '98%' }} />
                    <div className="mt-3">
                        {children}
                    </div>
                </div>

            </div>
        </>
    )
}
export default GridViewWithHeaderNode; 