import Licenses from "./Licenses/Licenses"
import { useCallback, useEffect, useRef, useState } from "react";
import InfoBar, { InfoMessageType } from "../InfoBar/InfoBar";
import Loader from "../Loader/Loader";
import { useGetLicensesInfo } from "src/Utils/Hooks/ManageLicenses/useGetLicensesInfo";
import { logger } from "src/Utils/LoggerService/LoggerService";
import { useInfobarTimeout } from "src/Utils/Hooks/InfobarTimeout/useInfobarTimeout";
import CloudStorage from "./CloudStorage/CloudStorage";
import NoLicenseActivated from "./NoLicenseActivated/NoLicenseActivated";

const LicensesAndStorage = () => {
    const { licensesInfo, licenseInfoError, licensesInfoLoading, getLicencesInfo } = useGetLicensesInfo();

    const [infoBar, setInfoBar] = useState<boolean>(false);
    const [pageLoader, setPageLoader] = useState<boolean>(false);
    const [storageLoader, setStorageLoader] = useState<boolean>(false);

    const msgType = useRef<InfoMessageType>(InfoMessageType.none);
    const msg = useRef('');
    const { setInfobarTimeout } = useInfobarTimeout();
    const clinicId = useRef<string>('')
    const updateUserLicenceList = useCallback(() => {
        getLicencesInfo(clinicId.current);
    }, [getLicencesInfo])

    useEffect(() => {
        if (licensesInfo) {
            clinicId.current = licensesInfo.clinicId;
        }
    }, [licensesInfo])

    const showInfoBar = useCallback((message: string, messageType: InfoMessageType) => {
        if (messageType === InfoMessageType.none) {
            setInfoBar(false);
          return;
        }
    
        msg.current = message;
        msgType.current = messageType;
        setInfoBar(true);
        setInfobarTimeout(() => setInfoBar(false));
      }, [setInfobarTimeout]);

      const handleUserLicenseUpdate = useCallback((message: string, messageType: InfoMessageType) => {
        if (messageType === InfoMessageType.success) {
            updateUserLicenceList();
        }
        showInfoBar(message, messageType);
      }, [updateUserLicenceList, showInfoBar]);
    
      // Handle cloud storage info update
      const handleCloudStorageUpdate = useCallback((message: string, messageType: InfoMessageType) => {
        showInfoBar(message, messageType);
      }, [showInfoBar]);

    useEffect(() => {
        if (licenseInfoError !== '') {
            logger.logError(`Failed to fetch the license details`)
            handleUserLicenseUpdate(licenseInfoError, InfoMessageType.error);
        }
    }, [licenseInfoError, handleUserLicenseUpdate])

    useEffect(() => {
        setPageLoader(licensesInfoLoading)
    }, [licensesInfoLoading])

    const clinicHasUserLicenses = () => licensesInfo  && licensesInfo.licenceInfo.length > 0; 
    return (
        <>
            {(pageLoader || storageLoader) && <Loader />}
            {infoBar && (
                <div className="row mt-3" style={{ paddingLeft: '1vw' }}>
                    <InfoBar
                        Message={msg.current}
                        MessageType={msgType.current}
                        closeClick={() => {
                            setInfoBar(false);
                        }}
                    />
                </div>
            )}

            {  
               clinicHasUserLicenses() && licensesInfo?.clinicId &&
                        <CloudStorage
                            clinicId={licensesInfo?.clinicId}
                            setInfo={handleCloudStorageUpdate}
                            updatePageLoader={setStorageLoader}
                        />
            }

            {
                !clinicHasUserLicenses() && licensesInfo?.clinicId && 
                  <NoLicenseActivated
                    clinicId={licensesInfo.clinicId}
                    setInfo={handleUserLicenseUpdate}
                    updateList={updateUserLicenceList}
                    updatePageLoader={setPageLoader} />
            }
            {
                (clinicHasUserLicenses() && licensesInfo?.clinicId  &&
                    <>
                        <div className="mt-3">
                            <Licenses
                                clinicId={licensesInfo.clinicId}
                                setInfo={handleUserLicenseUpdate}
                                updatePageLoader={setPageLoader}
                                licensesInfo={licensesInfo} />
                        </div>
                    </>
                )
            }
        </>
    )
}
export default LicensesAndStorage