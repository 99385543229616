import Dialog from "src/Components/Dialog/Dialog";
import tickIcon from 'src/images/tick-icon.svg';
import './ActivateLicenceSuccessInstruction.css';
import { Link } from "react-router-dom";
import Button from "src/Components/Button/Button";
import { Trans, useTranslation } from "react-i18next";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import { useClinicContext } from "src/Utils/Hooks/ClinicContext";
import { useState } from "react";
import AddNewLicence from "../AddNewLicence/AddNewLicence";
import { InfoMessageType } from "src/Components/InfoBar/InfoBar";

interface IActivateLicenseSuccessInstruction {
    onClose: () => void;
    setInfo: (message: string, type: InfoMessageType) => void;
    updatePageLoader: (load: boolean) => void;
}
const ActivateLicenseSuccessInstruction: React.FC<IActivateLicenseSuccessInstruction> = ({ onClose, setInfo, updatePageLoader }) => {
    const { t } = useTranslation();
    const enableCloudStorageActivation = Config.GetConfigData().enableCloudStorageActivation;
    const { currentUser } = useClinicContext()
    const [showAddNewLicence, setShowAddNewLicence] = useState<boolean>(false);
    return (
        <>
            {
                showAddNewLicence && currentUser &&
                <AddNewLicence
                    clinicId={currentUser.clinicUser.clinicId}
                    setInfo={setInfo}
                    updatePageLoader={updatePageLoader}
                    isUserLicence={false}
                    onClose={() => { onClose() }} />
            }
            {
                !showAddNewLicence &&
                <div className="activate-license-container">
                    <Dialog
                        title=''
                        headerAlign='left'
                        submitText=""
                        onClose={() => { onClose() }}
                        onSubmit={() => { }}
                        disableSubmit={true}
                        hideSubmit={true}
                    >
                        <div className='mt-3' style={{ textAlign: 'center' }}>
                            <img src={tickIcon} alt='success' />
                            <div className="mt-4 success-msg">
                                {t('LicenseActivationSuccessMessage')}
                            </div>
                            {enableCloudStorageActivation &&
                                <>
                                    <div className="mt-2">
                                        <Trans i18nKey='CloudStorageOrAppDownloadInstruction' />
                                    </div>
                                    <div className='mt-5 d-flex justify-content-center'>
                                        <Button
                                            backgroundColor='linear-gradient(109.8deg, rgba(168, 146, 125, 0.6) 0%, #A8927D 100%)'
                                            onClick={() => { setShowAddNewLicence(true); }}
                                            text={t('AddCloudStorage')}
                                            testId='newUser'
                                            color='white'
                                            disable={!currentUser?.isClinicAdmin}
                                        />
                                    </div>
                                </>
                            }
                            <div className='my-3'>
                                <Link to="/app">
                                    <Button
                                        text={t('DownloadAuraApp')}
                                        backgroundColor={`${enableCloudStorageActivation? 'none' : 'linear-gradient(109.8deg, rgba(168, 146, 125, 0.6) 0%, #A8927D 100%)'}`}
                                        borderColor="none"
                                        color= {`${enableCloudStorageActivation ? '#A8927D' : 'white'}`}
                                        onClick={() => { }}
                                    />
                                </Link>
                            </div>
                        </div>
                    </Dialog>
                </div>
            }
        </>
    )
}
export default ActivateLicenseSuccessInstruction