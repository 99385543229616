import { useCallback, useEffect, useState } from "react";
import AddNewLicence from "../AddNewLicence/AddNewLicence";
import { InfoMessageType } from "src/Components/InfoBar/InfoBar";
import StorageInfo from "./StorageInfo";
import { ClinicStorageError, useGetStorageLicenceInfo } from "src/Utils/Hooks/ManageLicenses/useGetStorageLicenceInfo";
import { Config } from "src/Utils/ConfigService/LoadConfig";
import ButtonWithIcon from "src/Components/ButtonWithIcon/ButtonWithIcon";
import { useClinicContext } from "src/Utils/Hooks/ClinicContext";
import LineSeparation from "src/SharedComponents/LineSeparation/LineSeparation";
import { useTranslation } from "react-i18next";

interface IStorageLicensesParams {
    clinicId:string;
    setInfo: (message: string, type: InfoMessageType) => void;
    updatePageLoader: (load: boolean) => void;
}

const CloudStorage: React.FC<IStorageLicensesParams> = ({ clinicId, setInfo, updatePageLoader }) => {
    const [showAddNewCloudStorage, setShowAddNewCloudStorage] = useState<boolean>(false);
    const { storageInfo, loading, getStorageInfo } = useGetStorageLicenceInfo()
    const enableCloudStorageActivation = Config.GetConfigData().enableCloudStorageActivation;
    const { currentUser } = useClinicContext()
    const { t } = useTranslation();
    useEffect(() => {
        updatePageLoader(loading)
    }, [loading, updatePageLoader])

    const setStorageInfoMessage = useCallback((message: string, messageType: InfoMessageType) => {
        if (messageType === InfoMessageType.success) {
            getStorageInfo();
        }
        setInfo(message, messageType);
    },[setInfo, getStorageInfo])

    return (
        <>
            {
                showAddNewCloudStorage &&
                <AddNewLicence
                    clinicId={clinicId}
                    setInfo={setStorageInfoMessage}
                    updatePageLoader={updatePageLoader}
                    onClose={() => { setShowAddNewCloudStorage(false); }}
                    isUserLicence={false} />
            }
            {storageInfo && 
                (
                     storageInfo.storageError === ClinicStorageError.STORAGE_LICENSE_NOT_ACTIVATED && enableCloudStorageActivation ?
                        <>
                            <div className="row mt-4">
                                <div className="col">
                                    <span className='px-2'><b>Cloud</b></span>
                                </div>
                                <div className="col d-flex justify-content-end">
                                    <ButtonWithIcon
                                        backgroundColor='linear-gradient(109.8deg, rgba(168, 146, 125, 0.6) 0%, #A8927D 100%)'
                                        onClick={() => { setShowAddNewCloudStorage(true) }}
                                        label={t('AddCloudStorage')}
                                        icon='bi bi-plus-lg'
                                        testId='addLicence'
                                        color='white'
                                        disable={!currentUser?.isClinicAdmin}
                                    />
                                </div>
                            </div>
                            <div className="mt-3">
                                <LineSeparation />
                            </div>
                        </>
                        : 
                        storageInfo.storageError !== ClinicStorageError.STORAGE_LICENSE_NOT_ACTIVATED &&
                            <div className="mb-3">
                                <StorageInfo
                                    storageInfo={storageInfo}
                                    setInfo={setInfo}
                                    updatePageLoader={updatePageLoader}
                                />
                            </div>
                )
            }
        </>
    )
}
export default CloudStorage;

